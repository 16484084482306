/** @jsx jsx */
import { useState } from 'react'
import { jsx, Card, Label, Box, Input, Button, Text } from 'theme-ui';
import { changePassword } from '../../utils/auth'
import styles from './styles'

const PasswordChangeForm = (props) => {

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [error, setError] = useState([])

  const isValid = () => {
    const errors = []
    if (!oldPassword || oldPassword === '') {
      errors.push({field: 'oldPassword', value: 'Required'})
    }

    if (!newPassword || newPassword === '') {
      errors.push({field: 'newPassword', value: 'Required'})
    }

    setError(errors)
    return !errors.length
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(false);

    if (!isValid()) return
    const request = await changePassword(oldPassword, newPassword)
    if (request.success) {
      props.onSuccess()
    } else {
      setError([{field: 'global', value: request.error?.message}])
    }
  }

  const Error = ({type, className}) => {
    if (!error || !Array.isArray(error))
      return null

    const found = error.find((x) => {
      return x.field === type
    })

    if (!found)
      return null

    return <Text className={className} sx={styles.error}>{found.value}</Text>
  }

  return (
    <Card 
      as="form"
      onSubmit={handleSubmit}
    >
      <Error type="global" sx={{mb: 2}} />
      <Box mb={2}>
        <Label mb={2}>Current Password</Label>
        <Input type="password" name='currentPassword' value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
        <Error type="oldPassword" />
      </Box>
      <Box mb={2}>
        <Label mb={2}>New Password</Label>
        <Input type="password" name='newPassword' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
        <Error type="newPassword" />
      </Box>
      <Button type="submit">Submit</Button>
    </Card>
  )
}

export default PasswordChangeForm