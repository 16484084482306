/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, Box, Heading, Flex, Text, Button } from 'theme-ui';
import { responsive } from '../../utils/helpers'
import { Kit } from '../registration'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { Stack } from 'raam'
import { getUser, logout } from '../../utils/auth'
import moment from 'moment';
import { navigate, Link } from 'gatsby'
import Modal, { Overlay, Close } from '../modal'
import PasswordChangeForm from '../passwordChangeForm'

const Profile = () => {

  const [hasMounted, setHasMounted] = useState(false)
  const [showPasswordChange, setShowPasswordChange] = useState()
  const handleLogout = async (e) => {
    e.preventDefault();
    logout();
    navigate('/');
  }

  useEffect(() => {
    setHasMounted(true)
  }, [])

  const profile = getUser()
  if (!profile || !hasMounted)
    return null
    
  const { attributes } = profile

  if (!profile || !attributes)
    return null

  const getBackgroundColor = () => {
    switch (attributes['custom:kitType']) {
      case 'home':
      default:
        return 'primarydark'
      case 'away':
        return 'away'
      case 'third':
        return 'third'
      case 'goalkeeper':
        return 'goalkeeper'
    }
  }

  const containerSX = {
    bg: getBackgroundColor(),
    textAlign: 'center',
    py: '70px',
  }

  const titleSX = {
    color: 'black',
    mb: '60px',
  }

  const contentSX = {
    display: responsive('block', 'grid', 5),
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 3,
  }

  const labelSX = {
    fontSize: 1,
    color: 'black',
  }

  const valueSX = {
    fontFamily: 'heading',
    fontSize: 8,
    lineHeight: 'body',
    wordBreak: 'break-word'
  }

  const boldSX = {
    fontFamily: 'heading',
    fontSize: 12,
    color: attributes['custom:kitType'] === 'away' ? 'primary' : 'white',
  }

  const sideBySide = {
    display: responsive('block', 'grid', 5),
    gridTemplateColumns: '1fr 90px',
    columnGap: 3,
  }

  const controlSX = {
    textAlign: 'left',
  }

  const playerWrapperSX = {
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: responsive(4, 0, 5)
  }

  const playerContainerSX = {
    justifyContent: 'space-between'
  }

  const playerSX = {
    width: '170px',
    height: '170px',
    flexShrink: 0,
    marginLeft: '20px',
  }

  const playerActionsSX = {
    mt: responsive(8, 0, 5),
    display: 'flex',
    flexWrap: responsive('wrap', 'nowrap', 5),
    justifyContent: responsive('center', 'flex-end', 5),
  }

  const kitContainerSX = {
    minWidth: '300px'
  }

  const buttonSX = {
    flexShrink: 0,
    mb: responsive(4, '0px', 5),
  }

  const accordionSX = {
    bg: 'white',
  }

  

  const Field = ({label, value, bold}) => {
    return (
      <Box sx={controlSX}>
        <Text sx={labelSX}>{label}</Text>
        <Text sx={valueSX}>{value}</Text>
        {bold &&
          <Text sx={boldSX}>{bold}</Text>
        }
      </Box>
    )
  }

  const formatDate = (string) => {
    if (!string || typeof string !== 'string')
      return ''
    return moment.unix(string).format('DD MMMM YYYY')
  }

  const getDateOfBirth = (string) => {
    if (!string || typeof string !== 'string' || string === '')
      return ''
    return moment().diff(string, 'years')    
  }

  return (
    <Flex sx={containerSX}>
      <Wrapper>
        <Inner>
          <Heading as="h1" sx={titleSX}>My FGR</Heading>
          <Box sx={contentSX}>
            <Box sx={kitContainerSX}>
              <Kit 
                type={attributes['custom:kitType']}
                name={attributes['family_name']} 
                number={attributes['custom:kitNumber']} 
                buyButton
              />
            </Box>
            <Stack>
              <Box sx={sideBySide}>
                <Field label='Name' value={`${attributes.name} ${attributes.family_name}`} />
                <Field label='Country' value={attributes['custom:country']} />
              </Box>
              <Box sx={sideBySide}>
                <Field label='Date Of Birth' value={`${attributes['custom:dateOfBirth']}`} />
                <Field label='Age' value={getDateOfBirth(attributes['custom:dateOfBirth'])} />
              </Box>
              <Field label='Signed Date' value={profile ? formatDate(attributes['custom:signup']) : ''} />
              {/* <Field label='Favourite Matchday Food' value="Q Pie, Chips and Gravy" /> */}
            </Stack>
            <Flex sx={playerWrapperSX}>
              <Flex sx={playerContainerSX}>
                <Field label='Your Favourite Player' value={attributes['custom:playerName']} bold={attributes['custom:playerNumber']} />
                <img sx={playerSX} src={attributes['custom:playerImage']} alt={attributes['custom:playerName']} />
              </Flex>
              <Box sx={playerActionsSX}>
                <Button as={Link} to="/edit-profile" sx={buttonSX} variant="secondary">Edit Profile</Button>
                <Button variant="secondary" sx={buttonSX} ml={responsive(0, 20, 5)} onClick={() => setShowPasswordChange(true)}>Change Password</Button>
                <Button variant="tertiary" sx={buttonSX} ml={responsive(0, 20, 5)} onClick={handleLogout}>Log out</Button>
              </Box>
            </Flex>
          </Box>
        </Inner>
      </Wrapper>
      {showPasswordChange &&
        <React.Fragment>
          <Overlay />
          <Modal 
            scrollToTop
          >
            <PasswordChangeForm 
              onSuccess={() => setShowPasswordChange(false)}
            />
          </Modal>
          <Close onClick={() => setShowPasswordChange(false)} />
        </React.Fragment>
      }
    </Flex>
  )
}

export default Profile