/** @jsx jsx */
import { jsx, Grid, Card, Box } from 'theme-ui';
import Layout from '../components/layout';
import Profile from '../components/profile'
import { TicketOffice } from '../components/tickets'
import { Subscriptions } from '../components/liveStream'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import { isLoggedIn } from '../utils/auth'
import { navigate } from 'gatsby'
import MetaData from '../components/metadata'

const MyFgr = (props) => {

  if (typeof window !== 'undefined' && !isLoggedIn()) {
    navigate('/')
    return null
  }

  return (
    <Layout {...props} dark>
      <MetaData title="My FGR" />
      <Profile />
      <Wrapper>
        <Inner>
          <Grid py={4}>
            <TicketOffice />
            <Subscriptions />
          </Grid>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default MyFgr